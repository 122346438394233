import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  pageID?: string
  label: string
  languagePrefix: string | null
  URL: string
}

export const LanguageItem = memo(function LanguageItem({
  label,
  languagePrefix,
  URL,
}: Props) {
  return (
    <Container>
      <Anchor to={URL} lang={languagePrefix === label ? undefined : label}>
        {label}
      </Anchor>
    </Container>
  )
})

const Container = styled.li`
  @media (max-width: 767px) {
    margin-bottom: 0.375rem;
  }
`

const Anchor = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: ${({ lang }) => (lang === undefined ? 700 : 400)};
  line-height: 0.875rem;
  opacity: ${({ lang }) => (lang === undefined ? 1 : 0.5)};
  margin-left: 0.75rem;
  text-transform: uppercase;
`

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo, useRef } from 'react'
import * as Icon from 'react-feather'
import Fade from 'react-reveal/Fade'

import { Syncrobox } from './Syncrobox'

export interface Props {
  IBEID?: number
  languagePrefix: string
  benefitsTitle?: string
  benefitsDescription?: string
  benefitsChecklist?: string[]
  modalStatus?: boolean
}

export const ModalIBE = memo(function ModalIBE({
  IBEID,
  languagePrefix,
  benefitsChecklist,
  benefitsDescription,
  benefitsTitle,
  modalStatus,
}: Props) {
  const benefitsRef = useRef(null)

  return (
    <Container row>
      <LeftSide dial={4}>
        <Fade left distance="7.5em" duration={600} when={modalStatus}>
          <Benefits ref={benefitsRef}>
            {benefitsTitle ? <Title>{benefitsTitle}</Title> : null}
            {benefitsDescription ? (
              <Description
                dangerouslySetInnerHTML={{ __html: benefitsDescription }}
              />
            ) : null}
            {benefitsChecklist
              ? benefitsChecklist.map((item, index) => (
                  <Benefit key={index} dial={4} row>
                    <IconWrap dial={5}>
                      <Icon.Check />
                    </IconWrap>
                    <Label>{item}</Label>
                  </Benefit>
                ))
              : null}
          </Benefits>
        </Fade>
      </LeftSide>
      <RightSide>
        <Wrap>
          {IBEID ? (
            <Syncrobox IBEID={IBEID} languagePrefix={languagePrefix} />
          ) : null}
        </Wrap>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 100%;
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 4rem 7.75rem 4rem 9.75rem;

  @media (max-width: 1199px) {
    padding: 3rem 3.75rem;
  }
`

const Benefits = styled.div``

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-bottom: 4.625rem;
`

const Benefit = styled(FlexBox)`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const IconWrap = styled(FlexBox)`
  width: 2.5rem;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  svg {
    height: 20px;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  line-height: 1.875rem;
  margin-left: 1.25rem;
`

const RightSide = styled(FlexBox)`
  justify-content: center;
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const Wrap = styled.div`
  padding: 4rem 0;
  margin: 0 auto;
`

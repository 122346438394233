import { Header as HeaderComponent } from 'app/components/Common/Header'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'

import { getHeaderProps } from './getHeaderProps'

export interface Props {
  pageID?: string
  languageCode?: string
  languagePrefix?: string | null
  offersButton?: { [key: string]: MenuItemProps[] }[]
  menuItems: { [key: string]: MenuItemProps[] }[]
}

export const Header = memo(function Header({
  pageID,
  languageCode,
  languagePrefix,
  offersButton,
  menuItems,
}: Props) {
  return (
    <StaticQuery
      query={graphql`
        query HeaderData {
          site {
            siteMetadata {
              title
            }
          }
          allSitePage {
            nodes {
              context {
                id
                languageCode
                languagePrefix
              }
              path
            }
          }
          allFile(filter: { name: { eq: "logo" } }) {
            nodes {
              name
              childImageSharp {
                gatsbyImageData(layout: FIXED)
              }
            }
          }
          cms {
            company {
              address
              country
              email
              facebook
              ibeid
              instagram
              phone
              postal_code
              locality
            }
            languages {
              prefix
            }
            rooms {
              hero_images {
                directus_files_id {
                  id
                  file {
                    childImageSharp {
                      gatsbyImageData(layout: FIXED, width: 700)
                    }
                  }
                }
              }
            }
            offers {
              hero_image {
                id
                file {
                  publicURL
                }
              }
            }
            reservation_benefits {
              translations {
                languages_code {
                  code
                  prefix
                }
                title
                description
                checklist
              }
            }
          }
        }
      `}
      render={(data) => {
        const props = getHeaderProps(
          data,
          languageCode || 'it-IT',
          languagePrefix || null,
          offersButton || [],
          menuItems,
          pageID,
        )

        return props ? <HeaderComponent pageID={pageID} {...props} /> : null
      }}
    />
  )
})

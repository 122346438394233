import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { useCookies } from 'react-cookie'

export interface Props {
  languageCode: string
  languagePrefix?: string
}

export const CookieMessage = memo(function CookieMessage({
  languageCode,
  languagePrefix,
}: Props) {
  const [cookies, setCookieMessage] = useCookies(['cookieMessage'])

  function handleSetCookie() {
    setCookieMessage('cookieMessage', 'privacy')
  }

  return cookies.cookieMessage !== 'privacy' ? (
    <Container
      dial={5}
      row
      wrap
      className={cookies.cookieMessage === 'privacy' ? 'hidden' : ''}
    >
      <Text>{useVocabularyData('cookie-message', languageCode)}</Text>
      <CTA onClick={handleSetCookie}>OK</CTA>
      <ReadMore
        rel="nofollow"
        to={
          languagePrefix ? `/${languagePrefix}/cookie-policy` : `/cookie-policy`
        }
      >
        {useVocabularyData('read-more', languageCode)}
      </ReadMore>
    </Container>
  ) : null
})

export const Container = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  line-height: 0.875rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 0.375rem 1.5rem;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 90;
  &.hidden {
    display: none;
  }

  @media (max-width: 767px) {
    justify-content: flex-start;
    z-index: 9999;
    text-align: left;
  }
`

export const Text = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`

export const CTA = styled.div`
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  cursor: pointer;
  margin: 0 0.75rem;
  padding: 0.3125rem 0.5rem;

  @media (max-width: 767px) {
    margin-left: 0;
  }
`

export const ReadMore = styled(Link)`
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
  white-space: nowrap;
  &:hover {
    background: ${({ theme }) =>
      theme.colors.getHoverColor(theme.colors.variants.primaryDark1)};
  }
`

import { Props as HeaderProps } from 'app/components/Common/Header'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { HeaderDataQuery } from 'graphql-types'
import { compact, uniqBy } from 'lodash'

export const getHeaderProps = (
  data: HeaderDataQuery,
  languageCode: string,
  languagePrefix: string | null,
  offersButton: { [key: string]: MenuItemProps[] }[],
  menuItems: { [key: string]: MenuItemProps[] }[],
  pageID?: string,
): HeaderProps | undefined => {
  const contacts = data.cms.company
  const address = contacts?.address || undefined
  const country = contacts?.country || undefined
  const locality = contacts?.locality || undefined
  const postalCode = contacts?.postal_code || undefined
  const email = contacts?.email || undefined
  const facebookURL = contacts?.facebook || undefined
  const IBEID = contacts?.ibeid || undefined
  const instagramURL = contacts?.instagram || undefined
  const currentLanguage = languagePrefix || 'it'

  const languagesList = data.allSitePage.nodes
    .filter((t) => t.context?.id === pageID)
    .map((node) => ({
      pageID: node.context?.id || undefined,
      label: node.context?.languagePrefix || 'it',
      languagePrefix: currentLanguage,
      URL: node.path,
    }))

  const logoObj =
    compact(data.allFile.nodes)[0].childImageSharp?.gatsbyImageData || undefined
  const logo = {
    width: logoObj?.width,
    height: logoObj?.height,
    src: logoObj?.images.fallback.src,
  }

  const phone = data.cms.company?.phone || undefined
  const siteName = data.site?.siteMetadata?.title || undefined

  const benefits = data.cms.reservation_benefits?.translations?.filter(
    (t) => t?.languages_code?.code === languageCode,
  )[0]

  const benefitsTitle = benefits?.title || undefined
  const benefitsDescription = benefits?.description || undefined
  const benefitsChecklist =
    compact(benefits?.checklist).map((item: any) => item.label) || undefined

  const navigationOffersButton = compact(
    compact(offersButton.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languagePrefix === languagePrefix || 'it')
  const offersButtonImage =
    data.cms.offers?.hero_image?.file?.publicURL || undefined

  const bookButtonImage =
    compact(data.cms.rooms?.hero_images)[0].directus_files_id?.file
      ?.childImageSharp?.gatsbyImageData.images.fallback?.src || undefined

  const navigationItems = compact(
    compact(menuItems.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return {
    address,
    country,
    locality,
    postalCode,
    benefitsTitle,
    benefitsDescription,
    benefitsChecklist,
    bookButtonImage,
    email,
    facebookURL,
    IBEID,
    instagramURL,
    languageCode,
    languagePrefix,
    languagesList: uniqBy(languagesList, 'label'),
    logo,
    offersButton: navigationOffersButton,
    offersButtonImage,
    menuItems: uniqBy(navigationItems, 'id') || undefined,
    phone,
    siteName,
  }
}

import 'swiper/swiper-bundle.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import * as Icon from 'react-feather'
import SwiperCore, { Autoplay, EffectFade, Lazy, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Autoplay, EffectFade, Lazy, Pagination])

export interface Props {
  claim?: string
  slides?: ImageProps[]
  pax?: string
  size?: string
  variant?: Variant
}

export const Hero = memo(function Hero({
  claim,
  pax,
  size,
  slides,
  variant = 'default',
}: Props) {
  return (
    <Container tag="section" variant={variant}>
      {claim ? (
        <Claim className="hero-claim">
          {claim}
          {size || pax ? (
            <InfoWrap dial={2} row tag="div">
              {size ? (
                <Size dial={5} row>
                  <Icon.Box />
                  {size}
                </Size>
              ) : null}
              {pax ? (
                <Pax dial={5} row>
                  <Icon.User />
                  {pax}
                </Pax>
              ) : null}
            </InfoWrap>
          ) : null}
        </Claim>
      ) : null}
      <Slider>
        {slides ? (
          <>
            <Swiper
              allowTouchMove={false}
              autoplay={{ delay: 4000 }}
              effect="fade"
              lazy
              loop
              pagination={
                slides.length > 1
                  ? {
                      clickable: true,
                    }
                  : false
              }
              preloadImages={false}
            >
              {slides.map((item, index) => (
                <SwiperSlide key={index}>
                  <Image
                    className="swiper-lazy"
                    src={item.src}
                    srcSet={item.srcSet}
                    alt={item.alt}
                    width={item.width}
                    height={item.height}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : null}
      </Slider>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          height: 70vh;

          .hero-claim {
            top: 68%;
            transform: none;
          }

          @media (max-width: 767px) {
            height: 45vh;
          }
        `
    }
  }}
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 4.375rem;
  padding: 0 8.375rem;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
  text-align: center;
  transform: translateY(-50%);
  z-index: 3;

  @media (max-width: 1023px) {
    font-size: 2.625rem;
    line-height: 3.375rem;
    padding: 0;
  }
`

const InfoWrap = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.625rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 0.75rem;
  margin-top: 1.5rem;
  text-transform: uppercase;
  svg {
    height: 1rem;
    margin-right: 0.5625rem;
  }
`

const Size = styled(FlexBox)`
  margin-right: 2.25rem;
`

const Pax = styled(FlexBox)``

const Slider = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  overflow: hidden;

  > .swiper-container {
    height: 100%;
    ${({ theme }) => {
      return css`
        &:before,
        &:after {
          content: '';
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
        &:before {
          background: ${theme.colors.variants.neutralDark1};
          height: 100%;
          opacity: 0.3;
        }
        &:after {
          background: linear-gradient(
            ${rgba(theme.colors.variants.neutralDark2, 0.7)},
            ${rgba(theme.colors.variants.neutralDark3, 0)}
          );
          height: 50%;
        }
      `
    }}

    .swiper-slide {
      height: 100%;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      bottom: 3.75rem;
      span {
        width: 3rem;
        height: 0.1875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border-radius: 0;
        margin: 0 0.3125rem;
        opacity: 0.3;
        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    > .swiper-container {
      .swiper-pagination {
        display: none;
      }
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'

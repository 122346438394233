import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'
import ReactGA from 'react-ga'

export const GoogleAnalytics = memo(function GoogleAnalytics() {
  return (
    <StaticQuery
      query={graphql`
        query GoogleAnalyticsData {
          cms {
            company {
              google_analytics_ua
            }
          }
        }
      `}
      render={(data) => {
        const UA = data.cms.company.google_analytics_ua

        if (typeof window != 'undefined' && UA) {
          ReactGA.initialize(UA, {
            gaOptions: {
              allowLinker: true,
            },
          })
          ReactGA.ga('require', 'linker')
          ReactGA.ga('linker:autoLink', ['simplebooking.it'])
          ReactGA.pageview(window.location.pathname + window.location.search)
        }

        return null
      }}
    />
  )
})

import { Footer as FooterComponent } from 'app/components/Common/Footer'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'

import { getFooterProps } from './getFooterProps'

export interface Props {
  languageCode: string
  languagePrefix: string
}

export const Footer = memo(function Footer({ languageCode }: Props) {
  return (
    <StaticQuery
      query={graphql`
        query FooterData {
          site {
            siteMetadata {
              title
            }
          }
          allFile(filter: { name: { eq: "logo" } }) {
            nodes {
              name
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 96)
              }
            }
          }
          cms {
            company {
              address
              country
              email
              phone
              postal_code
              mobile_phone
              fax
              vat
              facebook
              instagram
              ibeid
              locality
            }
          }
        }
      `}
      render={(data) => {
        const props = getFooterProps(data, languageCode)

        return props ? <FooterComponent {...props} /> : null
      }}
    />
  )
})

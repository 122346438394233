import { Props as FooterProps } from 'app/components/Common/Footer'
import { FooterDataQuery } from 'graphql-types'
import { compact } from 'lodash'

export const getFooterProps = (
  data: FooterDataQuery,
  languageCode: string,
): FooterProps | undefined => {
  const logoObj =
    compact(data.allFile.nodes)[0].childImageSharp?.gatsbyImageData || undefined
  const logo = {
    width: logoObj?.width,
    height: logoObj?.height,
    src: logoObj?.images.fallback.src,
  }
  const address = data.cms.company?.address || undefined
  const country = data.cms.company?.country || undefined
  const email = data.cms.company?.email || undefined
  const facebookURL = data.cms.company?.facebook || undefined
  const fax = data.cms.company?.fax || undefined
  const instagramURL = data.cms.company?.instagram || undefined
  const IBEID = data.cms.company?.ibeid || undefined
  const mobilePhone = data.cms.company?.mobile_phone || undefined
  const phone = data.cms.company?.phone || undefined
  const postalCode = data.cms.company?.postal_code || undefined
  const locality = data.cms.company?.locality || undefined
  const siteName = data.site?.siteMetadata?.title || undefined
  const vat = data.cms.company?.vat || undefined

  return {
    address,
    country,
    email,
    facebookURL,
    fax,
    instagramURL,
    IBEID,
    languageCode,
    logo,
    mobilePhone,
    phone,
    postalCode,
    locality,
    siteName,
    vat,
  }
}

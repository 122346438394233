import { StructuredData as StructuredDataComponent } from 'app/components/StructuredData'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'

import { getStructuredDataProps } from './getStructuredDataProps'

export interface Props {
  languageCode: string
}

export const StructuredData = memo(function StructuredData({
  languageCode,
}: Props) {
  return (
    <StaticQuery
      query={graphql`
        query StructuredDataData {
          site {
            siteMetadata {
              title
              siteUrl
            }
          }
          cms {
            company {
              address
              country
              email
              google_maps_url
              phone
              postal_code
              mobile_phone
              fax
              vat
              facebook
              instagram
              latitude
              longitude
              locality
              region
              rich_snippet_aggregate_rating_value
              rich_snippet_aggregate_best_rating
              rich_snippet_aggregate_rating_count
              rich_snippet_price_range
              rich_snippet_pets_allowed
              rich_snippet_number_of_rooms
            }
            home {
              hero_images {
                directus_files_id {
                  id
                  file {
                    childImageSharp {
                      gatsbyImageData(layout: FIXED)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const props = getStructuredDataProps(data, languageCode)

        return props ? <StructuredDataComponent {...props} /> : null
      }}
    />
  )
})

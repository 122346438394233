import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  className?: string
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  url: string
}

export const MenuItem = memo(function MenuItem({
  className,
  title,
  url,
}: Props) {
  if (!title || !url) {
    return null
  }

  return (
    <Anchor className={className} to={url} activeClassName="active">
      {title}
    </Anchor>
  )
})

const Anchor = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.7vh;
  line-height: 6vh;
  transition: 0.3s ease-out;
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }

  @media (max-width: 767px) {
    font-size: 3.5vh;
    line-height: 4.5vh;
  }
`

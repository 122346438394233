import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import * as Icon from 'react-feather'

export interface Props {
  calendarIcon?: boolean
  label: string
  file?: string
  target?: string
  URL?: string
  externalURL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
}

export const Button = memo(function Button({
  calendarIcon,
  label = 'Button',
  file,
  target,
  URL,
  externalURL,
  onClick,
  className,
  noActions,
  rel,
}: Props) {
  const buttonClassName = `${className ? className : ''}`

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
        >
          {calendarIcon ? <Icon.Calendar /> : null}
          {label}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            onClick != undefined ? (
              <Anchor className={buttonClassName} onClick={onClick}>
                {calendarIcon ? <Icon.Calendar /> : null}
                {label}
              </Anchor>
            ) : noActions ? (
              <Static className={buttonClassName}>
                {calendarIcon ? <Icon.Calendar /> : null}
                {label}
              </Static>
            ) : (
              <ButtonItem to={URL ? URL : '#'} className={buttonClassName}>
                {calendarIcon ? <Icon.Calendar /> : null}
                {label}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={externalURL}
              rel={rel}
              target={target}
            >
              {calendarIcon ? <Icon.Calendar /> : null}
              {label}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const ButtonStyle = css`
  cursor: pointer;
  display: inline-flex;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1rem;
  padding: 0.875rem 2rem;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  svg {
    height: 1rem;
    margin-right: 0.375rem;
  }
  background: ${theme.colors.variants.primaryDark1};
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  &:hover {
    padding-right: 2.625rem;
    padding-left: 2.625rem;
  }
`

const Anchor = styled.a`
  ${ButtonStyle}
`

const Static = styled.div`
  ${ButtonStyle}
`

const ButtonItem = styled(Link)`
  ${ButtonStyle}
`
